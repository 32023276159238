import i18n from "@/plugins/i18n";

const axios = require("axios");

export default class OrderApi {
  constructor(tenantId) {
    this.client = axios.create({
      baseURL: `${process.env.VUE_APP_TRAINPLANET_API_URL_BASE}`,
    });
    this.client.interceptors.request.use((request) => {
      request.headers["Accept-Language"] = i18n.global.locale;
      request.headers["tenantId"] = tenantId;
      request.headers["Content-Type"] = "application/json";
      request.headers["Authorization"] =
        "Bearer " + localStorage.getItem("auth_token");

      return request;
    });
  }
  getToken(orderId) {
    this.twoAuthHeaderIsRequired = true;
    return this.client.post(`orders/self-service/${orderId}/jwt/login`);
  }

  getPaymentSessionInvoice(system, orderId, checkoutUrl, confirmationUrl) {
    return this.client.get(`orders/jwt/${orderId}/payment/${system}`, {
      params: {
        checkoutUrl,
        confirmationUrl,
      },
    });
  }

  async confirmationCheck(pushUrl, selectedShippingSlug) {
    this.twoAuthHeaderIsRequired = false;
    return await this.client.post(pushUrl, {
      selectedShippingSlug,
    });
  }

  updateOrder(orderId, data) {
    this.twoAuthHeaderIsRequired = true;
    return this.client.patch(`orders/jwt/${orderId}`, data);
  }

  loginFromList(orderId, data) {
    this.twoAuthHeaderIsRequired = false;
    return this.client
      .post(`orders/self-service//${orderId}/login`, data)
      .then((response) => {
        localStorage.setItem("orderId", this.orderId);
        localStorage.setItem("password", this.loginToken);
        localStorage.setItem("token", response.data.token);
        return response;
      });
  }
  async getLoggedTaxiPayload(orderId, tenantId) {
    this.twoAuthHeaderIsRequired = true;
    const { data } = await this.client.get(`orders/jwt/taxi-payload`, {
      params: {
        orderId,
        tenantId,
      },
    });
    return data;
  }
}
